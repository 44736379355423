import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { navigate } from "gatsby";
interface Props {
  children: React.ReactNode;
  active?: boolean;
  onClick?: () => void | undefined;

  [x: string]: any;
}

export default function Tag({
  children,
  active = false,
  onClick = undefined,
  ...rest
}: Props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        cursor: "pointer",
        fontWeight: "bold",
        display: "inline-block",
        color: active
          ? theme.palette.primary.contrastText
          : theme.palette.custom.tagForeground,
        bgcolor: active
          ? theme.palette.primary.main
          : theme.palette.custom.tagBackground,
        borderRadius: 2,
        lineHeight: 1,
        fontSize: 12,
        px: 2,
        py: 1,
        mr: 1,
        mb: 1,
        "&:hover": {
          bgcolor: active
            ? theme.palette.primary.main
            : theme.palette.custom.tagBackgroundHovered,
        },
      }}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick();
        } else {
          navigate(`/search?keyword=${children}`);
        }
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
