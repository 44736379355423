import React, { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import Grid from "@mui/material/Grid";
import CardArticle, { CardArticleSkeleton } from "components/card-article";
import Title from "components/title";
import { Article } from "entities/article";
import { parseArticles } from "utils/article";
import EmptyArticles from "components/empty-articles";
import Zoom from "@mui/material/Zoom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
import { useQueryParam, StringParam } from "use-query-params";
import { PageContainer } from "components/layout";

interface Props {
  data: any;
}

const MENU_TAGS = [
  "Web Development",
  "iOS Development",
  "Android Development",
  "Cross Platform",
  "Trading",
];

export default function Articles({ data }: Props): React.ReactNode {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [_tag, setTag] = useQueryParam("tag", StringParam);
  const tag: string = _tag ?? "";

  const getAllArticles = (): Article[] => {
    return parseArticles(data.allArticles.nodes).filter(
      (article) =>
        tag?.length === 0 ||
        (tag === "Others"
          ? !article.tags.some((tag) =>
              MENU_TAGS.map((item: string) => item).includes(tag)
            )
          : article.tags.includes(tag ?? ""))
    );
  };

  const [allArticles, setAllArticles] = React.useState(getAllArticles());

  React.useEffect(() => {
    setAllArticles(getAllArticles());
  }, [tag]);

  React.useEffect(() => {
    setArticles([...allArticles.slice(0, 6)]);
  }, [allArticles]);

  // State for the articles
  const [articles, setArticles] = useState([...allArticles.slice(0, 6)]);

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allArticles.length > 6);

  //Set a ref for the loading div
  const loadRef = useRef<HTMLDivElement | null>(null);

  // Handle intersection with load more div
  const handleObserver = (entities: any) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  //Initialize the intersection observer API
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "100px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef?.current) {
      observer.observe(loadRef?.current);
    }
  }, []);

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = articles.length;
      const isMore = currentLength < allArticles.length;
      const nextResults = isMore
        ? allArticles.slice(currentLength, currentLength + 6)
        : [];
      const timeoutID = window.setTimeout(() => {
        setArticles([...articles, ...nextResults]);
        setLoadMore(false);
      }, 250);
      return () => {
        window.clearTimeout(timeoutID);
      };
    }
  }, [loadMore, hasMore]); //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = articles.length < allArticles.length;
    setHasMore(isMore);
  }, [articles]); //eslint-disable-line

  return (
    <PageContainer>
      <Helmet
        title={tag?.length === 0 ? "All Articles" : tag}
        titleTemplate="%s | Alfin's Blog"
      />

      <Grid container>
        <Grid item xs={12}>
          <Title>{tag?.length === 0 ? "All Articles" : tag}</Title>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          container
          alignItems="flex-start"
          sx={{ position: "relative" }}
        >
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            sx={{ top: 0, left: 0 }}
          >
            <Zoom
              in={articles.length === 0}
              key={tag}
              mountOnEnter
              unmountOnExit
            >
              <Grid item xs={12} sm={6} sx={{ mt: 6 }}>
                <EmptyArticles centered />
              </Grid>
            </Zoom>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={4}
            sx={{
              position: null,
              paddingTop: 4,
              top: 50,
              left: 0,
            }}
          >
            {articles.map((article: Article) => (
              <Zoom in mountOnEnter unmountOnExit key={article.id}>
                <Grid item xs={12} md={4}>
                  <CardArticle
                    image={article.hero_image}
                    to={`/article/${article.slug}`}
                    title={article.title}
                    subtitle={article.subtitle}
                    tags={article.tags}
                    date={article.formattedDate}
                    time={article.time}
                  />
                </Grid>
              </Zoom>
            ))}

            {hasMore && (
              <Grid item container xs={12} spacing={6}>
                <Grid item xs={12} md={4}>
                  <CardArticleSkeleton />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CardArticleSkeleton />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CardArticleSkeleton />
                </Grid>
              </Grid>
            )}

            <div ref={loadRef}></div>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

export const query = graphql`
  query {
    allArticles: allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          subtitle
          date
          formattedDate: date(formatString: "D MMMM YYYY")
          tags
          hero_image
        }
        id
        slug
        body
        fields {
          readingTime {
            minutes
          }
        }
      }
    }
  }
`;
