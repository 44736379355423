import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
  children: React.ReactNode;
}

export default function Title({ children }: Props) {
  return (
    <span style={{ position: "relative" }}>
      <Typography
        variant="h5"
        display="inline"
        sx={{
          fontWeight: "bold",
          pb: "5px",
          background:
            "linear-gradient(to left, #ffc700, #ffc700) no-repeat bottom left",
          backgroundSize: "85% 4px",
        }}
      >
        {children}
      </Typography>
    </span>
  );
}
