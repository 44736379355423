import { Article } from "entities/article";

export function parseArticles(data: any): Article[] {
  return data
    .map((item: any) => parseArticle(item));
}
export function parseArticle(data: any): Article {
  return {
    id: data.id,
    slug: data.slug,
    fileAbsolutePath: data.fileAbsolutePath,
    title: data.frontmatter.title,
    subtitle: data.frontmatter.subtitle,
    date: data.frontmatter.date,
    formattedDate: data.frontmatter.formattedDate,
    time: Math.ceil(data.fields.readingTime.minutes),
    hero_image: data.frontmatter.hero_image,
    tags: data.frontmatter.tags,
    body: data.body,
    tableOfContents: data.tableOfContents,
  };
}

export function parseTags(articles: Article[]): string[] {
  return [
    ...new Set(
      articles
        .map((article) => article.tags)
        .reduce((accumulator, value) => accumulator.concat(value), [])
    ),
  ] as string[];
}
